import React from "react";
import { Link } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import Layout from "../components/layout";
import FormerSuccess from "../components/farmersuccess";
import LetsChat from "../components/letsChat";
import ModalVideo from 'react-modal-video';
require("../css/how-it-works.css");
require("../css/responsive.scss");
require("../css/work-with-us.css"); 

class WorkwithUs extends React.Component {

  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    this.setState({ isOpen: true })
  }

  render() {
    return (
      <Layout>
      <div className="farmerBanner1">
          <div className="col-sm-12">
           
			
			
			
			<div className="col-md-12 mainContent ">
              <h3 className="hitWorksHead">Join the native circle organic movement</h3>
            </div>
            <div className="col-md-12 ">
              <p className="hitWorkssubHead">
                Join Us I Volunteer I Internships I
              </p>
			  <p className="hitWorkssubHead">
                Projects I SHG I  NGO I CSR
              </p>
            </div>
			
			
			
            <div className="col-sm-6 farmerPoints">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12 farmerbtnMain">
                    {/* <button className="downloadfarmer" href="">Download the App <img className="iconCheckout" src="https://res.cloudinary.com/djoztpm50/image/upload/v1551253843/Native%20Circle/path-down.png"/></button> */}

					
						
						
                    <a
											href="#letsChat"
											// to="/work-with-us"
                      // target="_blank"
                    >
                      <button className="how-wks-chv-Btn">
                       Contact us{" "}
                        <img
                          className="iconCheckout"
                          src="https://res.cloudinary.com/djoztpm50/image/upload/v1551181856/Native%20Circle/path.svg"
                        />
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container pad0">
          <div className="col-sm-12 pad0 connectFarmerMain">
           
          <h5 className="team-jnt"> Join our team </h5>
		  
		  <p className="team-par">There are many ways through which you can contribute to the empowerment of the farming <br/> sector through the Native Circle. If you are genuinely willing to join the Native Circle organic <br/> movement.</p>
		  
          </div>
        </div>


        

        <div className="col-sm-12 pad0 HowitworksMainDiv">
          <div className="container">
            <div className="col-sm-12 pad0">
              <h5 className="team-jnt">Current openings</h5>

              <div className="row">
               
			   {/* <div className="col-sm-1"></div> */}
			   
			   <div className="col-md-3 flip-card wrk-us-flpk">
				<div className="box4 flip-card-in work-teaming-tp">
				<div className="flip-card-front">
				<h6 className="CuratedApp-wrk-us">House Keeping Executive</h6>
				<p className="lcn-bngs"> Location	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	:	&nbsp;&nbsp;&nbsp; Bangalore </p>
                <p className="lcn-bngs"> Salary		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	:	&nbsp;&nbsp;&nbsp; 	Not disclosed </p>
                <p className="lcn-bngs"> Experience	&nbsp;&nbsp;&nbsp;	:	&nbsp;&nbsp;&nbsp; 	2-5 years</p>
				 <div className="col-sm-12 apply-nw-bts">
				<a href="mailto:info@nativecircle.in" className="text-ig-nw-apl">Apply now <img className="watchVideoIcon" src="https://res.cloudinary.com/djoztpm50/image/upload/v1561552192/path-copy-4_elkbii.png"/></a></div>
				</div>
				</div>
				</div>
				
				{/* <div className="col-sm-1"></div> */}

                <div className="col-md-3  flip-card wrk-us-flpk">
				<div className="box4 flip-card-in work-teaming-tp">
				<div className="flip-card-front">
				<h6 className="CuratedApp-wrk-us">Marketing manager</h6>
				<p className="lcn-bngs"> Location	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	:	&nbsp;&nbsp;&nbsp; Chennai </p>
                <p className="lcn-bngs"> Salary		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	:	&nbsp;&nbsp;&nbsp; 	Not disclosed </p>
                <p className="lcn-bngs"> Experience	&nbsp;&nbsp;&nbsp;	:	&nbsp;&nbsp;&nbsp; 	1 year</p>
				 <div className="col-sm-12 apply-nw-bts">
				<a href="mailto:info@nativecircle.in" className="text-ig-nw-apl">Apply now <img className="watchVideoIcon" src="https://res.cloudinary.com/djoztpm50/image/upload/v1561552192/path-copy-4_elkbii.png"/></a></div>
				</div>
				</div>
				</div>
				
				{/* <div className="col-sm-1"></div> */}
				
				<div className="col-md-3 flip-card wrk-us-flpk">
				<div className="box4 flip-card-in work-teaming-tp">
				<div className="flip-card-front">
				<h6 className="CuratedApp-wrk-us">House Keeping Executive</h6>
				<p className="lcn-bngs"> Location	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	:	&nbsp;&nbsp;&nbsp; Bangalore </p>
                <p className="lcn-bngs"> Salary		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	:	&nbsp;&nbsp;&nbsp; 	Not disclosed </p>
                <p className="lcn-bngs"> Experience	&nbsp;&nbsp;&nbsp;	:	&nbsp;&nbsp;&nbsp; 	2-5 years</p>
				 <div className="col-sm-12 apply-nw-bts">
				<a href="mailto:info@nativecircle.in" className="text-ig-nw-apl">Apply now <img className="watchVideoIcon" src="https://res.cloudinary.com/djoztpm50/image/upload/v1561552192/path-copy-4_elkbii.png"/></a></div>
				</div>
				</div>
				</div>
				
				
				
               
              </div>
            </div>
          </div>
        </div>
		
		
		
		<div className="col-sm-12 volunt-dv-mnt">
			<div className="container pad0">
				<div className="col-sm-12 pad0">
					<div className="row">
					   <div class="col-md-9 col-sm-12 col-xs-12">
							<h1 class="volunteer-hds">Volunteer</h1>
							<h5 className="leisur-worth">Make your leisure worthy by volunteering our mission.</h5>
							<p class="donation-farmers">You can also make your valuable donation for our farmer support programs.<br/>
															Reach us through email. </p>
						</div>
						<div class=" col-md-3 col-sm-12  col-xs-12 ">
							<img className="hand-imgs" src="https://res.cloudinary.com/djoztpm50/image/upload/v1565280050/group-10_fm4ryy-min_pr74n6.png"/>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		
		<div className="col-sm-12 volunt-dv-mnt-altr">
			<div className="container pad0">
				<div className="col-sm-12 pad0">
					<div className="row">
					    <div class=" col-md-5 col-sm-12  col-xs-12 ">
							<img className="hand-imgs" src="https://res.cloudinary.com/djoztpm50/image/upload/v1565279906/group-16_qefxij-min_kcl6gq.png"/>
						</div>
					   <div class="col-md-7 col-sm-12 col-xs-12">
							<h1 class="volunteer-hds">Internships</h1>
							<h5 className="leisur-worth">Motivated individuals across national and international academic institutions are welcome to work as interns with native circle.</h5>
							<p class="donation-farmers">Involve yourself in data collection and analysis,
														Designing the training modules and courseware,
														Providing research consultation. Reach us through email with a CV. </p>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div className="col-sm-12 volunt-dv-mnt">
			<div className="container pad0">
				<div className="col-sm-12 pad0">
					<div className="row">
					   <div class="col-md-9 col-sm-12 col-xs-12">
							<h1 class="volunteer-hds">Projects</h1>
							<h5 className="leisur-worth">Students are encouraged to take up projects on different aspects of natural farming along with their academics </h5>
							<p class="donation-farmers">You can also make your valuable donation for our farmer support programs.<br/>
														Reach us through email.</p>
						</div>
						<div class=" col-md-3 col-sm-12  col-xs-12 ">
							<img className="hand-imgs" src="https://res.cloudinary.com/djoztpm50/image/upload/v1565280089/group-21_rbyypt-min_uvstp5.png"/>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div className="col-sm-12 volunt-dv-mnt-altr">
			<div className="container pad0">
				<div className="col-sm-12 pad0">
					<div className="row">
					    <div class=" col-md-5 col-sm-12  col-xs-12 ">
							<img className="hand-imgs" src="https://res.cloudinary.com/djoztpm50/image/upload/v1565279962/group-14_wsilsg-min_y7t1d3.png"/>
						</div>
					   <div class="col-md-7 col-sm-12 col-xs-12">
							<h1 class="volunteer-hds">SHG</h1>
							<h5 className="leisur-worth">We encourage the motivated groups of individuals who aim to complete the full circle of organic life cycle with us</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div className="col-sm-12 volunt-dv-mnt">
			<div className="container pad0">
				<div className="col-sm-12 pad0">
					<div className="row">
					   <div class="col-md-9 col-sm-12 col-xs-12">
							<h1 class="volunteer-hds">FPO</h1>
							<h5 className="leisur-worth">Native circle extends association with the organizations who share the same genuine vision and mission, as we do. </h5>
						</div>
						<div class=" col-md-3 col-sm-12  col-xs-12 ">
							<img className="hand-imgs" src="https://res.cloudinary.com/djoztpm50/image/upload/v1565279780/group-12_ivlx1d-min_pcfy29.png"/>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div className="col-sm-12 volunt-dv-mnt-altr">
			<div className="container pad0">
				<div className="col-sm-12 pad0">
					<div className="row">
					    <div class=" col-md-5 col-sm-12  col-xs-12 ">
							<img className="hand-imgs" src="https://res.cloudinary.com/djoztpm50/image/upload/v1565280008/group-20_jsl6fl-min_mvn1du.png"/>
						</div>
					   <div class="col-md-7 col-sm-12 col-xs-12">
							<h1 class="volunteer-hds">CSR</h1>
							<h5 className="leisur-worth">We team up with companies which can  integrate environmental and social concerns in their business operations </h5>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	<div id="letsChat">	<LetsChat /></div>
      </Layout>
    );
  }
}

export default WorkwithUs;
