
import React from "react";

class LetsChat extends React.Component {
    render() {
        return (
            <div className="container pad0">
                <div className="col-sm-12 pad0 connectFarmerMain">
                    <p className="letChat">LET’S CHAT</p>
                    <h5 className="connectFarmerHead">Get in touch</h5>
                    <div className="col-sm-12 MainContact">
                        <div className="row centerMob">
                            <div className="col-sm-6 pad0">
                                <h5 className="ourAddress">Our address</h5>
                                <address className="addrInfo">
                                    <span>NATIVE CIRCLE,</span>
                                    <br />
                                    Hemagaru,
                                    <br />
                                    Siddapura, North Kanara,
                                     <br />
                                     Karnataka  -581329
                                     <br />
                                </address>
                            </div>
                            <div className="col-sm-6 pad0">
                                <div className="EmailContact">
                                    <h5 className="EmailHead">Email</h5>
                                    <p className="EmailInfo">
                                        {" "}
                                        iamconnekted@gmail.com/info@nativecircle.in
                      </p>
                                    <h5 className="EmailHead">Contact Number</h5>
                                    <p className="EmailInfo">+91 7338388575</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LetsChat;
